<template>
  <div class="big-div">
    <!-- 文件列表 -->
    <div class="content-big-div">
      <div class="content-div">
        <div class="title-div">{{ data.titleName }}</div>
        <div class="file-div" v-for="item in fileList" :key="item.fileId">
          <div class="left-div">
            {{ item.fileName + item.fileFormat }}
          </div>
          <div class="right-div" @click="viewClick(item)">
            查看
          </div>
        </div>
      </div>
    </div>
    <FilePreview v-model="showPreview" :file="file"></FilePreview>
  </div>
</template>

<script>
  import contractBusiness from '@/api/contractBusiness'
  import FilePreview from './components/FilePreview.vue'

  export default {
    components: {
      FilePreview,
    },
    data() {
      return {
        data: {},
        fileList: [],
        showPreview:false,
        file:{}
      }
    },
    mounted() {
      this.getFileList()
    },
    methods: {
      // 获取签署文件
      async getFileList() {
        try {
          let res = await contractBusiness.draft({
            masterId: this.$route.query.masterId,
          })
          this.data = res
          this.fileList = res.fileList
          if (res.status !== 200) {
            this.$toast(res.msg)
            this.$toast.clear()
          }
        } catch (res) {
          console.log('res: ', res)
        }
      },
      // 查看
      viewClick(val) {
        this.showPreview = true
        this.file = val
        console.log('val: ', this.file);
      },
    },
  }
</script>

<style lang="less" scoped>
  .big-div {
    background: #f5f5f5;
    .content-big-div {
      width: 100%;
      padding-top: 20px;
      padding-bottom: 16px;
      background: #ffffff;
      .content-div {
        width: 347px;
        margin-left: 14px;
        margin-bottom: 20px;
        .title-div {
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
          height: 20px;
          font-size: 15px;
          font-weight: 500;
          text-align: left;
          color: #111a34;
          line-height: 20px;
        }
        .file-div {
          width: 347px;
          height: 46px;
          background: #f8f9fa;
          border: 1px solid #eeeeee;
          border-radius: 4px;
          margin-top: 10px;
          display: flex;
          justify-content: space-between;
          padding: 13px 12px 0 12px;
          &:nth-child(1) {
            margin-top: 11px;
          }
          .left-div {
            // width: 72px;
            height: 20px;
            font-size: 13px;
            font-weight: 400;
            text-align: left;
            color: #111a34;
            line-height: 20px;
          }
          .right-div {
            // width: 26px;
            height: 20px;
            font-size: 13px;
            font-weight: 400;
            text-align: left;
            color: #1676ff;
            line-height: 20px;
          }
        }
      }
    }
  }
</style>
