<template>
  <transition name="van-slide-up">
    <div class="file-preview-page" v-if="show">
      <div class="preview-page-title">
        <span class="title-text">{{ file.fileName }}</span>
        <span class="title-close-button" @click="close">退出预览</span>
      </div>
      <div class="preview-page-content">
        <ul class="file-img-list">
          <li
            class="file-img-item"
            v-for="(item, index) in file.imgList"
            :key="index"
          >
            <img :src="item" alt="" />
          </li>
        </ul>
      </div>
    </div>
  </transition>
</template>

<script>
  import { mapState } from 'vuex'
  import contractSign from '@/api/contractSign'

  export default {
    name: 'FilePreview',
    props: {
      value: {
        type: Boolean,
        default: false,
      },
      file: {
        type: Object,
        default: () => {},
      },
    },
    watch: {
      value: {
        handler(newVal) {
          if (newVal) {
            this.open()
          } else {
            this.close()
          }
        },
        immediate: true,
      },
      show: {
        handler(newVal) {
          this.$emit('input', newVal)
        },
      },
    },
    data() {
      return {
        show: false,
      }
    },
    methods: {
      open() {
        this.show = true
      },
      close() {
        this.show = false
      },
    },
  }
</script>

<style lang="less" scoped>
  .file-preview-page {
    position: fixed;
    width: 100%;
    height: 100vh;
    background: #fff;
    left: 0;
    top: 0;
    z-index: 99;
    .preview-page-title {
      width: 100%;
      height: 48px;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 14px;
      border-bottom: 1px solid #f5f5f5;
      position: static;
      top: 0;
      box-shadow: 2px 0 3px #cccccc;
      z-index: 10;
      .title-text {
        width: 230px;
        text-align: center;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      .title-close-button {
        position: absolute;
        right: 16px;
        color: @BLUE;
      }
    }
    .preview-page-content {
      margin-top: 16px;
      padding-bottom: 80px;
      height: calc(100% - 48px);
      box-sizing: border-box;
      overflow-y: auto;
      -webkit-overflow-scrolling: touch;
      position: relative;
      .page-item {
        width: 100%;
        height: 100%;
        box-shadow: 0 1px 2px 2px #ccc;
        .image {
          width: 100%;
          height: 100%;
        }
      }
      .page-item + .page-item {
        margin-top: 16px;
      }
    }
  }

  .file-img-list {
    width: 100%;
    .file-img-item {
      width: 100%;
      box-shadow: 0 0 2px 1px #ccc;
      img {
        width: 100%;
      }
    }
  }
</style>
